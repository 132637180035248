import { Link, useParams, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import { FiArrowUpRight } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import UnknownIcon from "../../public/images/UnknownCoin.png";
import "../style/converter.scss";
import top250 from "../utils/250.json";
import all from "../utils/list.json";
import fiat from "../utils/fiat.json";
import axios from "axios";

import GoldBar from "../../public/images/GoldBar.png";
import SilverBar from "../../public/images/SilverBar.png";
import IMFIcon from "../../public/images/IMFIcon.png";
import { binanceCryptoIcons } from "binance-icons";
import tableConverter from "../utils/tableConverter";
import PriceChart from "../components/PriceChart";
import months from "../utils/timedata";
import Breadcrumbs from "../components/Breadcrumbs";

const Converter = () => {
  const { convert_id } = useParams();

  const [urlData, setUrlData] = useState({ num: null, from: null, to: null });
  const [is30DaysSelected, setIs30DaysSelected] = useState(7);

  const [data250, setData250] = useState([...top250, ...fiat]);
  const [dataAll, setDataAll] = useState([...all, ...fiat]);

  const [currencyFrom, setCurrencyFrom] = useState({
    id: "",
    symbol: "btc",
    name: "Bitcoin",
    image: "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1696501400",
  });
  const [currencyTo, setCurrencyTo] = useState({
    id: "",
    symbol: "btc",
    name: "Bitcoin",
    image: "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1696501400",
  });
  const [amountFrom, setAmountFrom] = useState(0);
  const [amountTo, setAmountTo] = useState(0);

  const [inputCurrencyFrom, setInputCurrencyFrom] = useState(null);
  const [inputCurrencyTo, setInputCurrencyTo] = useState(null);
  const [inputValue, setInputValue] = useState(0);

  const [dataPrice, setDataPrice] = useState({ mode: null, priceData: null });
  const [coinChart, setCoinChart] = useState({
    prices: [
      [1712178106323, 65714.2207783053],
      [1712178381725, 65603.0967360958],
      [1712178736621, 65602.01388926705],
      [1712178952732, 65664.32013767218],
      [1712179242503, 65659.50318582787],
      [1712179512489, 65776.2995299977],
      [1712179861483, 65791.48883477683],
      [1712180171760, 65840.40748204238],
      [1712180459170, 65819.74601114877],
      [1712180755232, 65807.42748261972],
      [1712181069683, 65905.02098799139],
      [1712181370832, 65956.64455523822],
      [1712181647610, 65955.03909587838],
      [1712181959564, 65988.3056752151],
      [1712182198778, 65995.13300518083],
      [1712182505777, 66007.41686538077],
      [1712182837390, 66018.91401382844],
      [1712183107752, 66032.817860034],
      [1712183457321, 66029.88691798523],
      [1712183767463, 65976.54210346045],
      [1712184125941, 66096.38363620377],
      [1712184292403, 66161.05223799632],
      [1712184595572, 66205.17413058125],
      [1712184944644, 66217.79384175412],
      [1712185235230, 66212.36390036279],
      [1712185507053, 66216.5929840552],
      [1712185901475, 66227.57808634755],
      [1712186073075, 66285.7570591799],
      [1712186527212, 66207.03238421856],
      [1712186806671, 66197.40787269737],
      [1712186999969, 66203.17222142107],
      [1712187387594, 66165.59683417354],
      [1712187682760, 66070.93321956173],
      [1712187871119, 66153.13794171502],
      [1712188208723, 66143.5939304653],
      [1712188530597, 66123.938539074],
      [1712188849931, 65977.52963468556],
      [1712189165700, 65980.32732162582],
      [1712189427015, 66103.92369277785],
      [1712189768444, 66277.65304847271],
      [1712190030442, 66188.80850620911],
      [1712190299810, 66362.59706296328],
      [1712190648022, 66207.00776611235],
      [1712190943574, 66173.98218510768],
      [1712191262083, 66140.97754138045],
      [1712191500945, 66237.23940347164],
      [1712191841071, 66345.85172196731],
      [1712192118283, 66305.71801658986],
      [1712192416662, 66282.96477205383],
      [1712192778583, 66230.05063037717],
      [1712193066579, 66257.39601868919],
      [1712193323455, 66280.70942090255],
      [1712193594218, 66258.6385849631],
      [1712193917098, 66268.37879487243],
      [1712194356573, 66229.00992466403],
      [1712194558099, 66186.7289399707],
      [1712194873528, 66066.16488631367],
      [1712195212416, 66128.7689272633],
      [1712195449220, 66066.83379052319],
      [1712195813088, 66098.26222721812],
      [1712196003637, 66153.3532735734],
      [1712196291535, 66141.08758201798],
      [1712196617005, 66093.0498883823],
      [1712196991366, 66043.95325340118],
      [1712197276679, 65938.36988448465],
      [1712197513395, 65902.95516750962],
      [1712197864259, 65625.95422057643],
      [1712198121234, 65548.54036534816],
      [1712198431820, 65727.83962002656],
      [1712198719612, 65679.47697323581],
      [1712199003574, 65747.25056558067],
      [1712199304745, 65850.48561701646],
      [1712199588848, 65814.7986952181],
      [1712199983635, 65759.00506412704],
      [1712200221606, 65706.3118576623],
      [1712200525963, 65715.99346348121],
      [1712200801607, 65736.20413057013],
      [1712201129263, 65833.46691773577],
      [1712201394097, 65664.19849241155],
      [1712201723499, 65714.37389427287],
      [1712201996822, 65658.71289437676],
      [1712202307691, 65628.47930115757],
      [1712202605533, 65749.95390133317],
      [1712202929420, 65660.12755342199],
      [1712203242078, 65698.28348738086],
      [1712203568827, 65680.34047861735],
      [1712203911730, 65478.86479797162],
      [1712204099959, 65482.157634437586],
      [1712204422317, 65291.42474063592],
      [1712204689014, 65135.00750513468],
      [1712205047727, 65244.30237527839],
      [1712205289422, 65214.94539306946],
      [1712205578476, 65363.68830912391],
      [1712205984037, 65327.61554326543],
      [1712206217590, 65439.00386843415],
      [1712206508443, 65486.85033136247],
      [1712206772458, 65536.53608747096],
      [1712207194170, 65463.6675623765],
      [1712207491763, 65468.83291649714],
      [1712207692073, 65568.75186241051],
      [1712208053134, 65492.86571880116],
      [1712208299026, 65428.851351514524],
      [1712208619404, 65398.385052242615],
      [1712208994614, 65426.704138461464],
      [1712209227981, 65480.502471969536],
      [1712209532399, 65505.53414497388],
      [1712209814508, 65523.91160916469],
      [1712210197334, 65562.54301583584],
      [1712210407856, 65619.9905509694],
      [1712210702435, 65593.04388042155],
      [1712211062925, 65645.93894539918],
      [1712211375846, 65684.44373202596],
      [1712211657186, 65678.67829101767],
      [1712211930542, 65635.07517235528],
      [1712212224343, 65651.39617806711],
      [1712212502918, 65704.87219823408],
      [1712212833212, 65780.16403725452],
      [1712213100359, 65801.39640255737],
      [1712213452999, 65767.29740854699],
      [1712213764689, 65768.07345006515],
      [1712214030275, 65800.60278272994],
      [1712214316362, 65689.79955332387],
      [1712214632531, 65799.49419167748],
      [1712214939721, 66012.12653837977],
      [1712215198120, 66210.68783165979],
      [1712215521903, 66141.19091926435],
      [1712215863738, 66136.33130571584],
      [1712216123172, 66080.08262124566],
      [1712216452807, 66200.91020439498],
      [1712216779809, 66110.28257016111],
      [1712217084528, 66067.69321655252],
      [1712217308032, 66055.60832695603],
      [1712218217538, 66176.3884324411],
      [1712218529989, 66356.90420478932],
      [1712218845844, 66370.33247870636],
      [1712219117139, 66353.72881255328],
      [1712219430381, 66277.54013686338],
      [1712219741597, 66265.88531925448],
      [1712220048426, 66227.85397135955],
      [1712220374491, 66214.29186804997],
      [1712220682178, 66172.43212120965],
      [1712220952961, 66236.41081102684],
      [1712221335481, 66237.84530016877],
      [1712221559141, 66305.98159880679],
      [1712221879806, 66353.25203442888],
      [1712222134357, 66266.64243888565],
      [1712222430171, 66219.50295476342],
      [1712222713740, 66220.47918427897],
      [1712223024402, 66263.21098338233],
      [1712223315125, 66231.08719683305],
      [1712223599487, 66314.84323722652],
      [1712223893806, 66278.24506120941],
      [1712224178766, 66245.87255404517],
      [1712224549537, 66282.405411214],
      [1712224792116, 66290.03029357774],
      [1712225132303, 66242.94111567822],
      [1712225401091, 66178.08351485615],
      [1712225722329, 66197.77323451548],
      [1712226034094, 66261.88575436482],
      [1712226318534, 66501.69570609438],
      [1712226645734, 66403.68666673027],
      [1712226911561, 66338.4035057977],
      [1712227226569, 66321.8171792791],
      [1712227539370, 66291.75055327054],
      [1712227784978, 66370.71249680183],
      [1712228098518, 66383.26160513605],
      [1712228403627, 66258.70283762734],
      [1712228697978, 66297.48459261676],
      [1712229047572, 66230.93381430194],
      [1712229238167, 66211.64436218495],
      [1712229672828, 66182.31920885072],
      [1712229948770, 66211.15682243048],
      [1712230234879, 66226.96101747343],
      [1712230506965, 66279.41329030647],
      [1712230814773, 66356.11319818863],
      [1712231099467, 66404.60479665486],
      [1712231431522, 66361.45125215405],
      [1712231683500, 66406.5532871695],
      [1712232035175, 66336.89383199692],
      [1712232357390, 66340.5602601717],
      [1712232625374, 66424.09776918616],
      [1712232897173, 66527.4343356936],
      [1712233220147, 66480.28249269011],
      [1712233589960, 66527.01301180634],
      [1712233822283, 66568.19754106364],
      [1712234119369, 66495.38003965486],
      [1712234429459, 66622.7349419009],
      [1712234695271, 66654.6465862389],
      [1712234998916, 66744.00810120115],
      [1712235329142, 66760.09884073334],
      [1712235598026, 66601.96670974715],
      [1712235970438, 66783.43383594378],
      [1712236248543, 66895.76583181815],
      [1712236566692, 67057.185556917],
      [1712236871228, 67414.27945080466],
      [1712237148634, 67391.02292930691],
      [1712237434020, 67274.26531835043],
      [1712237722735, 67295.77737041964],
      [1712237977493, 67304.5547617117],
      [1712238298073, 67384.10516791303],
      [1712238626608, 67400.7461592189],
      [1712238895448, 67373.49980868185],
      [1712239339686, 67260.32176218054],
      [1712239484613, 67207.37637525781],
      [1712239786484, 67497.59732849532],
      [1712240168516, 67698.50032617392],
      [1712240429494, 67626.08832667925],
      [1712240730348, 67576.84466677146],
      [1712241041260, 67480.77517767447],
      [1712241292829, 67613.61397574187],
      [1712241656248, 67758.78466260711],
      [1712241967789, 67967.43725588366],
      [1712242227768, 67892.1493354403],
      [1712242518694, 67841.94573652756],
      [1712242794451, 67736.44129523821],
      [1712243118861, 67712.62756628517],
      [1712243409855, 67683.8802625382],
      [1712243733307, 67645.56544154848],
      [1712244089452, 67565.53290490646],
      [1712244311106, 67499.87107862295],
      [1712244676199, 67637.34101096295],
      [1712244891008, 67544.66336012872],
      [1712245276240, 67626.31374665229],
      [1712245532302, 67781.90225860752],
      [1712245833275, 67727.50824925932],
      [1712246126142, 67805.42629914786],
      [1712246415168, 67648.2485257229],
      [1712246694691, 67638.05459456639],
      [1712247009563, 67565.0678841642],
      [1712247337698, 67620.1649342234],
      [1712247639075, 67820.39933659391],
      [1712247940667, 68120.34183347638],
      [1712248216021, 67870.59092601587],
      [1712248524289, 67997.93431462464],
      [1712248804104, 67936.59424484383],
      [1712249130512, 67948.43007268166],
      [1712249411274, 67932.87261906458],
      [1712249711887, 68047.40406261127],
      [1712250089386, 67861.7411985384],
      [1712250281092, 67806.33511837569],
      [1712250587143, 67801.64759923215],
      [1712250907609, 67845.55755006777],
      [1712251263423, 67829.0492066514],
      [1712251563077, 67869.5979697905],
      [1712251852984, 67860.7882011401],
      [1712252164649, 67965.27208350443],
      [1712252396987, 68168.88993177358],
      [1712252695787, 68154.85780292346],
      [1712252993611, 68334.31384206061],
      [1712253291275, 68814.19544374023],
      [1712253656832, 68929.46405006284],
      [1712253933776, 69199.13410727492],
      [1712254257969, 68931.58941665516],
      [1712254488306, 68777.33610534211],
      [1712254866034, 68990.87163223562],
      [1712255118771, 68902.90515207074],
      [1712255387551, 69025.31723678474],
      [1712255711940, 68666.96560629252],
      [1712256059612, 68837.12630585623],
      [1712256361983, 68969.63659137051],
      [1712256689282, 69012.61686572336],
      [1712256893685, 68919.33974396657],
      [1712257185570, 68628.86092664195],
      [1712257519732, 68495.71383256752],
      [1712257844618, 68560.43092361445],
      [1712258147648, 68499.03072470131],
      [1712258468026, 68546.93075666991],
      [1712258686428, 68743.70037321199],
      [1712259034063, 68838.09929064196],
      [1712259288988, 68858.5581543468],
      [1712259685072, 68959.22459907194],
      [1712259968125, 68783.6748020941],
      [1712260257876, 68778.34165895455],
      [1712260555389, 68677.56481251471],
      [1712260829688, 68472.36076999798],
      [1712261101487, 68384.13190507532],
      [1712261451388, 67822.19707670626],
      [1712261745938, 67753.5528871567],
      [1712262022202, 67383.29583658288],
      [1712262330527, 67503.02252470654],
      [1712262607340, 67711.41563553839],
      [1712262877612, 67901.73950217493],
      [1712263248305, 67843.61099766823],
      [1712263511562, 67852.69614807464],
      [1712263790758, 67931.22361689516],
      [1712264153227, 67952.51842099463],
      [1712264406000, 67974.4235238163],
    ],
    market_caps: [
      [1712178106323, 1292890743316.486],
      [1712178381725, 1292890743316.486],
      [1712178736621, 1290219750831.582],
      [1712178952732, 1290219750831.582],
      [1712179242503, 1290484834379.1047],
      [1712179512489, 1290484834379.1047],
      [1712179861483, 1294279774527.7056],
      [1712180171760, 1294279774527.7056],
      [1712180459170, 1294892416655.027],
      [1712180755232, 1294892416655.027],
      [1712181069683, 1296333168164.3713],
      [1712181370832, 1296333168164.3713],
      [1712181647610, 1297497604591.9473],
      [1712181959564, 1297497604591.9473],
      [1712182198778, 1298286746229.3657],
      [1712182505777, 1298286746229.3657],
      [1712182837390, 1298754577218.3074],
      [1712183107752, 1298754577218.3074],
      [1712183457321, 1298447819695.2993],
      [1712183767463, 1298447819695.2993],
      [1712184125941, 1299212795213.918],
      [1712184292403, 1299212795213.918],
      [1712184595572, 1302419169518.2874],
      [1712184944644, 1302419169518.2874],
      [1712185235230, 1302695745751.7266],
      [1712185507053, 1302695745751.7266],
      [1712185901475, 1303510955261.5908],
      [1712186073075, 1303510955261.5908],
      [1712186527212, 1302448801076.1187],
      [1712186806671, 1302448801076.1187],
      [1712186999969, 1302382302744.9392],
      [1712187387594, 1302382302744.9392],
      [1712187682760, 1300583149748.7483],
      [1712187871119, 1300583149748.7483],
      [1712188208723, 1300583149748.7483],
      [1712188530597, 1301670235623.7266],
      [1712188849931, 1301670235623.7266],
      [1712189165700, 1301670235623.7266],
      [1712189427015, 1299392815518.266],
      [1712189768444, 1300565879765.2927],
      [1712190030442, 1303144030494.4805],
      [1712190299810, 1303144030494.4805],
      [1712190648022, 1302433644815.5186],
      [1712190943574, 1302433644815.5186],
      [1712191262083, 1302372913032.4849],
      [1712191500945, 1302372913032.4849],
      [1712191841071, 1305192085292.988],
      [1712192118283, 1305192085292.988],
      [1712192416662, 1303955336324.256],
      [1712192778583, 1303955336324.256],
      [1712193066579, 1303452333591.84],
      [1712193323455, 1303452333591.84],
      [1712193594218, 1303476778046.4473],
      [1712193917098, 1303476778046.4473],
      [1712194356573, 1302912445300.319],
      [1712194558099, 1302912445300.319],
      [1712194873528, 1301342020267.2495],
      [1712195212416, 1301342020267.2495],
      [1712195449220, 1299660001450.9634],
      [1712195813088, 1299660001450.9634],
      [1712196003637, 1301406803775.8682],
      [1712196291535, 1301406803775.8682],
      [1712196617005, 1300220877466.2417],
      [1712196991366, 1300220877466.2417],
      [1712197276679, 1297207176307.5164],
      [1712197513395, 1297207176307.5164],
      [1712197864259, 1293144180274.0076],
      [1712198121234, 1293144180274.0076],
      [1712198431820, 1292130359659.2036],
      [1712198719612, 1292130359659.2036],
      [1712199003574, 1293419755959.9458],
      [1712199304745, 1293419755959.9458],
      [1712199588848, 1294750249070.307],
      [1712199983635, 1294750249070.307],
      [1712200221606, 1292616421784.586],
      [1712200525963, 1292616421784.586],
      [1712200801607, 1293204481618.6067],
      [1712201129263, 1293204481618.6067],
      [1712201394097, 1291788334822.3506],
      [1712201723499, 1291788334822.3506],
      [1712201996822, 1291680418610.5708],
      [1712202307691, 1291680418610.5708],
      [1712202605533, 1293475370367.3848],
      [1712202929420, 1293475370367.3848],
      [1712203242078, 1292768194195.859],
      [1712203568827, 1292768194195.859],
      [1712203911730, 1289403364930.527],
      [1712204099959, 1289403364930.527],
      [1712204422317, 1289403364930.527],
      [1712204689014, 1284457370958.038],
      [1712205047727, 1281285567610.1064],
      [1712205289422, 1281285567610.1064],
      [1712205578476, 1285880230998.9019],
      [1712205984037, 1285880230998.9019],
      [1712206217590, 1287361891402.5444],
      [1712206508443, 1287361891402.5444],
      [1712206772458, 1287361891402.5444],
      [1712207194170, 1289242340186.0342],
      [1712207491763, 1288530923677.5564],
      [1712207692073, 1288530923677.5564],
      [1712208053134, 1288423922662.2913],
      [1712208299026, 1288423922662.2913],
      [1712208619404, 1288423922662.2913],
      [1712208994614, 1286566079536.5059],
      [1712209227981, 1286566079536.5059],
      [1712209532399, 1288181555005.4387],
      [1712209814508, 1289035532109.1946],
      [1712210197334, 1289035532109.1946],
      [1712210407856, 1289035532109.1946],
      [1712210702435, 1289035532109.1946],
      [1712211062925, 1290555621011.3655],
      [1712211375846, 1291438574287.8428],
      [1712211657186, 1291948449080.54],
      [1712211930542, 1291948449080.54],
      [1712212224343, 1291220437286.2646],
      [1712212502918, 1291220437286.2646],
      [1712212833212, 1291220437286.2646],
      [1712213100359, 1294079550610.9524],
      [1712213452999, 1294157090192.3103],
      [1712213764689, 1294157090192.3103],
      [1712214030275, 1294482098424.0896],
      [1712214316362, 1294482098424.0896],
      [1712214632531, 1294427711438.2756],
      [1712214939721, 1294427711438.2756],
      [1712215198120, 1302550016838.8037],
      [1712215521903, 1302550016838.8037],
      [1712215863738, 1300324068561.9404],
      [1712216123172, 1300324068561.9404],
      [1712216452807, 1302008425115.108],
      [1712216779809, 1302008425115.108],
      [1712217084528, 1299608490436.0183],
      [1712217308032, 1299608490436.0183],
      [1712218217538, 1300040194482.4478],
      [1712218529989, 1301878560231.4795],
      [1712218845844, 1305693993525.7134],
      [1712219117139, 1305693993525.7134],
      [1712219430381, 1303752228680.084],
      [1712219741597, 1303752228680.084],
      [1712220048426, 1302892292696.81],
      [1712220374491, 1302892292696.81],
      [1712220682178, 1302892292696.81],
      [1712220952961, 1302904847581.0862],
      [1712221335481, 1302904847581.0862],
      [1712221559141, 1304332366269.3552],
      [1712221879806, 1304332366269.3552],
      [1712222134357, 1303823964956.9104],
      [1712222430171, 1303823964956.9104],
      [1712222713740, 1302736222865.0056],
      [1712223024402, 1303315577080.939],
      [1712223315125, 1303315577080.939],
      [1712223599487, 1304605279521.6335],
      [1712223893806, 1304605279521.6335],
      [1712224178766, 1304605279521.6335],
      [1712224549537, 1303059306182.0303],
      [1712224792116, 1303059306182.0303],
      [1712225132303, 1304392397287.0476],
      [1712225401091, 1301916473631.5015],
      [1712225722329, 1301916473631.5015],
      [1712226034094, 1303565108804.1875],
      [1712226318534, 1303565108804.1875],
      [1712226645734, 1306870746694.0854],
      [1712226911561, 1306870746694.0854],
      [1712227226569, 1304744533162.5723],
      [1712227539370, 1304744533162.5723],
      [1712227784978, 1304744533162.5723],
      [1712228098518, 1305934790386.753],
      [1712228403627, 1303503684178.5808],
      [1712228697978, 1303503684178.5808],
      [1712229047572, 1302957849382.0713],
      [1712229238167, 1302957849382.0713],
      [1712229672828, 1301823462182.6272],
      [1712229948770, 1301823462182.6272],
      [1712230234879, 1302887532043.7046],
      [1712230506965, 1302887532043.7046],
      [1712230814773, 1302887532043.7046],
      [1712231099467, 1305267270391.9934],
      [1712231431522, 1305525910579.7715],
      [1712231683500, 1305525910579.7715],
      [1712232035175, 1305525910579.7715],
      [1712232357390, 1305525910579.7715],
      [1712232625374, 1305701816276.6812],
      [1712232897173, 1308987718268.062],
      [1712233220147, 1307864935471.6301],
      [1712233589960, 1307864935471.6301],
      [1712233822283, 1309465794325.6106],
      [1712234119369, 1309465794325.6106],
      [1712234429459, 1310500773518.6948],
      [1712234695271, 1310500773518.6948],
      [1712234998916, 1310500773518.6948],
      [1712235329142, 1313053603238.7764],
      [1712235598026, 1313053603238.7764],
      [1712235970438, 1310259625255.2886],
      [1712236248543, 1316040401209.3584],
      [1712236566692, 1316040401209.3584],
      [1712236871228, 1323868415550.8818],
      [1712237148634, 1323868415550.8818],
      [1712237434020, 1324051312898.482],
      [1712237722735, 1324051312898.482],
      [1712237977493, 1324051312898.482],
      [1712238298073, 1324365082834.4575],
      [1712238626608, 1325975687999.2676],
      [1712238895448, 1325975687999.2676],
      [1712239339686, 1324447400993.211],
      [1712239484613, 1324447400993.211],
      [1712239786484, 1327882729654.5896],
      [1712240168516, 1327882729654.5896],
      [1712240429494, 1327882729654.5896],
      [1712240730348, 1330410537816.029],
      [1712241041260, 1327325920909.6846],
      [1712241292829, 1327325920909.6846],
      [1712241656248, 1332530127916.778],
      [1712241967789, 1332530127916.778],
      [1712242227768, 1335644765885.6426],
      [1712242518694, 1335644765885.6426],
      [1712242794451, 1332582396423.2861],
      [1712243118861, 1332582396423.2861],
      [1712243409855, 1331549172805.49],
      [1712243733307, 1331549172805.49],
      [1712244089452, 1329189109970.9507],
      [1712244311106, 1329189109970.9507],
      [1712244676199, 1329164335760.0266],
      [1712244891008, 1329164335760.0266],
      [1712245276240, 1329045413673.7712],
      [1712245532302, 1329045413673.7712],
      [1712245833275, 1329045413673.7712],
      [1712246126142, 1332407468893.19],
      [1712246415168, 1330849066865.186],
      [1712246694691, 1330849066865.186],
      [1712247009563, 1330849066865.186],
      [1712247337698, 1329212647865.2534],
      [1712247639075, 1334235802678.7622],
      [1712247940667, 1334235802678.7622],
      [1712248216021, 1335223225581.8296],
      [1712248524289, 1335223225581.8296],
      [1712248804104, 1336521713823.381],
      [1712249130512, 1336521713823.381],
      [1712249411274, 1336521713823.381],
      [1712249711887, 1336448498000.304],
      [1712250089386, 1336180164926.379],
      [1712250281092, 1336180164926.379],
      [1712250587143, 1333868593384.454],
      [1712250907609, 1333868593384.454],
      [1712251263423, 1333868593384.454],
      [1712251563077, 1334407667947.3738],
      [1712251852984, 1334861153147.8267],
      [1712252164649, 1334861153147.8267],
      [1712252396987, 1341095910765.7024],
      [1712252695787, 1341095910765.7024],
      [1712252993611, 1341095910765.7024],
      [1712253291275, 1344350318015.8547],
      [1712253656832, 1344350318015.8547],
      [1712253933776, 1344350318015.8547],
      [1712254257969, 1357135771375.4617],
      [1712254488306, 1357135771375.4617],
      [1712254866034, 1358575116437.6384],
      [1712255118771, 1358575116437.6384],
      [1712255387551, 1358575116437.6384],
      [1712255711940, 1355827625257.0774],
      [1712256059612, 1355827625257.0774],
      [1712256361983, 1354244832312.2131],
      [1712256689282, 1358219261978.2537],
      [1712256893685, 1358219261978.2537],
      [1712257185570, 1358219261978.2537],
      [1712257519732, 1350149306782.0125],
      [1712257844618, 1350149306782.0125],
      [1712258147648, 1347507462530.5793],
      [1712258468026, 1348232554305.3215],
      [1712258686428, 1348232554305.3215],
      [1712259034063, 1353625036131.1772],
      [1712259288988, 1353625036131.1772],
      [1712259685072, 1358488122711.08],
      [1712259968125, 1358488122711.08],
      [1712260257876, 1352200858296.7185],
      [1712260555389, 1352200858296.7185],
      [1712260829688, 1347072981377.6729],
      [1712261101487, 1347072981377.6729],
      [1712261451388, 1336406627372.9067],
      [1712261745938, 1336406627372.9067],
      [1712262022202, 1325648424817.0542],
      [1712262330527, 1325648424817.0542],
      [1712262607340, 1332103607651.8557],
      [1712262877612, 1332103607651.8557],
      [1712263248305, 1334704320059.8765],
      [1712263511562, 1334704320059.8765],
      [1712263790758, 1334704320059.8765],
      [1712264153227, 1336427945021.0828],
      [1712264406000, 1336427945021.0828],
    ],
    total_volumes: [
      [1712178106323, 36340257392.739174],
      [1712178381725, 36173981332.836494],
      [1712178736621, 35860630414.08536],
      [1712178952732, 35814916267.24101],
      [1712179242503, 35742698700.750206],
      [1712179512489, 35936111850.64708],
      [1712179861483, 35806755565.76753],
      [1712180171760, 35756356376.00447],
      [1712180459170, 34933706613.01059],
      [1712180755232, 35053459047.42526],
      [1712181069683, 35543293975.336784],
      [1712181370832, 35684006910.58921],
      [1712181647610, 29430731112.22564],
      [1712181959564, 29255927714.95723],
      [1712182198778, 28915060588.143753],
      [1712182505777, 29019093939.269455],
      [1712182837390, 28836254616.02503],
      [1712183107752, 34834904221.505196],
      [1712183457321, 34966648716.36849],
      [1712183767463, 35188286312.68647],
      [1712184125941, 35661239769.50397],
      [1712184292403, 35848527966.53891],
      [1712184595572, 35979077923.861565],
      [1712184944644, 34586426857.43367],
      [1712185235230, 35237487049.25912],
      [1712185507053, 35176554747.08826],
      [1712185901475, 29148675269.809914],
      [1712186073075, 29309059698.069458],
      [1712186527212, 29183544599.700672],
      [1712186806671, 29016976869.959568],
      [1712186999969, 28253318088.545525],
      [1712187387594, 35606110074.209335],
      [1712187682760, 35460130066.03835],
      [1712187871119, 35714458966.42688],
      [1712188208723, 35606383953.97656],
      [1712188530597, 35559952961.797325],
      [1712188849931, 35203949176.354836],
      [1712189165700, 35552976771.23619],
      [1712189427015, 35509078806.1098],
      [1712189768444, 35559926048.14388],
      [1712190030442, 29376207918.144535],
      [1712190299810, 35516366542.95521],
      [1712190648022, 29238064519.1425],
      [1712190943574, 28874128252.458035],
      [1712191262083, 34623337011.58025],
      [1712191500945, 34558957037.27169],
      [1712191841071, 34740124377.36556],
      [1712192118283, 34375449013.55182],
      [1712192416662, 34220236334.483418],
      [1712192778583, 33796399553.663345],
      [1712193066579, 33319573528.21635],
      [1712193323455, 32862826662.640766],
      [1712193594218, 33106953141.61424],
      [1712193917098, 32802895871.365044],
      [1712194356573, 33312917158.290302],
      [1712194558099, 33285869793.573826],
      [1712194873528, 33346938272.16403],
      [1712195212416, 33345996712.45431],
      [1712195449220, 33151532166.184605],
      [1712195813088, 26192630195.15322],
      [1712196003637, 33045503109.355656],
      [1712196291535, 32773659228.27088],
      [1712196617005, 32834317920.2556],
      [1712196991366, 32831251878.773655],
      [1712197276679, 32799354714.300983],
      [1712197513395, 32786048982.49832],
      [1712197864259, 27031563756.888958],
      [1712198121234, 27121693458.808365],
      [1712198431820, 27065809932.47889],
      [1712198719612, 26344131261.429867],
      [1712199003574, 26427013210.704124],
      [1712199304745, 27252827121.083115],
      [1712199588848, 32658613114.255123],
      [1712199983635, 31001414225.268997],
      [1712200221606, 32827499030.95388],
      [1712200525963, 32612768698.40181],
      [1712200801607, 32074471614.688095],
      [1712201129263, 32309489667.66895],
      [1712201394097, 32469477538.039772],
      [1712201723499, 32589488327.736675],
      [1712201996822, 26836081465.819225],
      [1712202307691, 32445353132.211617],
      [1712202605533, 32415272273.009068],
      [1712202929420, 32429217653.277615],
      [1712203242078, 32363922930.81034],
      [1712203568827, 32265540065.149044],
      [1712203911730, 32201068341.023884],
      [1712204099959, 32182452987.284817],
      [1712204422317, 32183683365.331093],
      [1712204689014, 32237489723.29962],
      [1712205047727, 32334517469.723785],
      [1712205289422, 31146110962.833035],
      [1712205578476, 32437765354.900135],
      [1712205984037, 32336755074.235413],
      [1712206217590, 26687949327.748722],
      [1712206508443, 31669691333.172188],
      [1712206772458, 32193197713.27844],
      [1712207194170, 32272948395.782784],
      [1712207491763, 32272242023.533524],
      [1712207692073, 32396419615.22225],
      [1712208053134, 32250823683.396347],
      [1712208299026, 32152080974.058735],
      [1712208619404, 31954287687.868584],
      [1712208994614, 26489042367.34527],
      [1712209227981, 32045944065.81772],
      [1712209532399, 32328936359.72322],
      [1712209814508, 32001680915.021244],
      [1712210197334, 30426551552.06582],
      [1712210407856, 32337519523.427387],
      [1712210702435, 31925851688.36361],
      [1712211062925, 31931565196.100185],
      [1712211375846, 31664672527.88138],
      [1712211657186, 31361941614.256706],
      [1712211930542, 31333872262.785313],
      [1712212224343, 31507667156.257187],
      [1712212502918, 31366030344.631275],
      [1712212833212, 31704523967.463116],
      [1712213100359, 31653710199.690643],
      [1712213452999, 31511007941.174313],
      [1712213764689, 26400177636.710163],
      [1712214030275, 26316439485.495697],
      [1712214316362, 31582203756.080338],
      [1712214632531, 31339170481.552643],
      [1712214939721, 31673272438.427635],
      [1712215198120, 31816224817.836735],
      [1712215521903, 31777596566.384834],
      [1712215863738, 30974991171.813797],
      [1712216123172, 31012402292.541832],
      [1712216452807, 31414401234.073055],
      [1712216779809, 29923449166.637184],
      [1712217084528, 31899179444.333775],
      [1712217308032, 31655040199.13909],
      [1712218217538, 31798716340.967205],
      [1712218529989, 31863218436.83718],
      [1712218845844, 31841484112.597057],
      [1712219117139, 31770258666.201595],
      [1712219430381, 31714924372.68138],
      [1712219741597, 31276074902.977013],
      [1712220048426, 31268951713.11362],
      [1712220374491, 31441955705.96488],
      [1712220682178, 31448133805.610085],
      [1712220952961, 31313222770.19113],
      [1712221335481, 30960949429.29574],
      [1712221559141, 31204650066.10283],
      [1712221879806, 31312364352.75563],
      [1712222134357, 31229441631.073112],
      [1712222430171, 31208131884.897907],
      [1712222713740, 31159324994.83847],
      [1712223024402, 31107309151.15669],
      [1712223315125, 31074841729.91096],
      [1712223599487, 31192963564.298996],
      [1712223893806, 30818571397.612057],
      [1712224178766, 30942041838.08216],
      [1712224549537, 30488164065.647346],
      [1712224792116, 30519707187.904984],
      [1712225132303, 30715597258.83341],
      [1712225401091, 30734279826.213425],
      [1712225722329, 30584090477.0555],
      [1712226034094, 30539217858.36483],
      [1712226318534, 30973132984.018726],
      [1712226645734, 30930750835.27689],
      [1712226911561, 26029896237.3104],
      [1712227226569, 30515860443.62287],
      [1712227539370, 30140728755.35736],
      [1712227784978, 30429424881.725178],
      [1712228098518, 30566530802.00667],
      [1712228403627, 30596259318.839813],
      [1712228697978, 30449170288.35037],
      [1712229047572, 30295377404.79152],
      [1712229238167, 30160661642.65439],
      [1712229672828, 30075414245.02389],
      [1712229948770, 29764392236.821903],
      [1712230234879, 26913275004.047493],
      [1712230506965, 29815632345.073086],
      [1712230814773, 29885715692.335136],
      [1712231099467, 29903460703.77973],
      [1712231431522, 29804980751.409794],
      [1712231683500, 29237291101.773712],
      [1712232035175, 29804161457.17851],
      [1712232357390, 29284508747.898743],
      [1712232625374, 29569294780.076775],
      [1712232897173, 29958991169.932858],
      [1712233220147, 29965187044.96268],
      [1712233589960, 25076401187.833027],
      [1712233822283, 29626246658.889263],
      [1712234119369, 29606726540.62133],
      [1712234429459, 29708168633.212677],
      [1712234695271, 30131894755.621353],
      [1712234998916, 29939481537.312057],
      [1712235329142, 29964402451.60992],
      [1712235598026, 30089585002.14047],
      [1712235970438, 29853726523.698803],
      [1712236248543, 30641367590.02664],
      [1712236566692, 30699964438.608448],
      [1712236871228, 31035281235.36177],
      [1712237148634, 31130691300.541092],
      [1712237434020, 30791092041.458298],
      [1712237722735, 25262765578.281445],
      [1712237977493, 25774172767.340572],
      [1712238298073, 30929641217.463707],
      [1712238626608, 31265298095.68576],
      [1712238895448, 30664539317.149364],
      [1712239339686, 31441691540.89881],
      [1712239484613, 31293681652.70412],
      [1712239786484, 31364044280.16658],
      [1712240168516, 31801054463.986355],
      [1712240429494, 24398612532.45341],
      [1712240730348, 26722065540.663937],
      [1712241041260, 27235355436.62609],
      [1712241292829, 27319598289.744614],
      [1712241656248, 31843407593.99685],
      [1712241967789, 32028349932.45025],
      [1712242227768, 32076418462.629864],
      [1712242518694, 27487255323.96568],
      [1712242794451, 27555690554.168095],
      [1712243118861, 32533183265.873158],
      [1712243409855, 28160139244.39083],
      [1712243733307, 31883849892.4721],
      [1712244089452, 32402482337.386482],
      [1712244311106, 32583467599.303432],
      [1712244676199, 32639601492.202282],
      [1712244891008, 32485510898.23656],
      [1712245276240, 32329762221.797485],
      [1712245532302, 32459388849.00036],
      [1712245833275, 32389810446.36648],
      [1712246126142, 32400764483.86609],
      [1712246415168, 31934773674.717426],
      [1712246694691, 23355314318.74301],
      [1712247009563, 32077143709.484184],
      [1712247337698, 31949420422.58324],
      [1712247639075, 32196970611.512775],
      [1712247940667, 32337369198.5023],
      [1712248216021, 32317806558.63345],
      [1712248524289, 31608800405.46829],
      [1712248804104, 31948404802.259888],
      [1712249130512, 31983762294.09639],
      [1712249411274, 31772394835.36672],
      [1712249711887, 32106054434.780327],
      [1712250089386, 32225774309.950977],
      [1712250281092, 32581439696.854637],
      [1712250587143, 32404304138.650803],
      [1712250907609, 32393297092.88314],
      [1712251263423, 30922114996.558475],
      [1712251563077, 25041692351.574783],
      [1712251852984, 32018228440.836365],
      [1712252164649, 32418642482.90658],
      [1712252396987, 32705864199.581287],
      [1712252695787, 32680337020.78688],
      [1712252993611, 32809257039.93353],
      [1712253291275, 33172971183.26049],
      [1712253656832, 33458764946.30786],
      [1712253933776, 33845513000.71627],
      [1712254257969, 33635108106.119022],
      [1712254488306, 33598356248.85483],
      [1712254866034, 33841183460.03571],
      [1712255118771, 33956434739.2113],
      [1712255387551, 34105277800.831856],
      [1712255711940, 34301445645.29117],
      [1712256059612, 34528608159.5826],
      [1712256361983, 34605606827.70056],
      [1712256689282, 34861494272.65312],
      [1712256893685, 34770619579.90239],
      [1712257185570, 34894385150.856155],
      [1712257519732, 35078604251.361145],
      [1712257844618, 34799062159.239204],
      [1712258147648, 34981961203.5903],
      [1712258468026, 34994369677.80836],
      [1712258686428, 35272434522.899574],
      [1712259034063, 35416243927.09196],
      [1712259288988, 35443388827.41921],
      [1712259685072, 35618285412.92181],
      [1712259968125, 34009051785.727276],
      [1712260257876, 35612740856.206825],
      [1712260555389, 35678806639.7361],
      [1712260829688, 35731766097.703896],
      [1712261101487, 35691228266.00574],
      [1712261451388, 35701451023.55003],
      [1712261745938, 35834863793.787575],
      [1712262022202, 36048209212.866844],
      [1712262330527, 35864691041.0619],
      [1712262607340, 36293482373.93665],
      [1712262877612, 36752393675.358406],
      [1712263248305, 32425811190.861134],
      [1712263511562, 36832268474.42666],
      [1712263790758, 36857922705.69244],
      [1712264153227, 36998863825.74916],
      [1712264406000, 36987743043.24166],
    ],
  });

  useEffect(() => {
    if (currencyFrom.id !== "")
      axios
        .get("https://api.coingecko.com/api/v3/coins/" + currencyFrom.id + "/market_chart", {
          params: {
            vs_currency: "usd",
            days: is30DaysSelected,
          },
        })
        .then((res) => {
          console.log("Chart Data - Okay");
          setCoinChart(res.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
  }, [is30DaysSelected, currencyFrom]);

  useEffect(() => {
    if (convert_id) {
      const [beforeTo, afterTo] = convert_id.split("-to-");
      const [numberAm, ...rest] = beforeTo.split("-");

      document.getElementsByTagName("META")[2].content = `Current value of ${numberAm} ${dataAll
        .filter((el) => el.id === rest.join("-"))[0]
        .symbol.toUpperCase()} in ${dataAll.filter((el) => el.id === afterTo)[0].symbol.toUpperCase()} is ${amountTo} ${dataAll
        .filter((el) => el.id === afterTo)[0]
        .symbol.toUpperCase()}. You are viewing real time data for the current value of ${dataAll
        .filter((el) => el.id === rest.join("-"))[0]
        .symbol.toUpperCase()} in ${dataAll.filter((el) => el.id === afterTo)[0].name} (${dataAll
        .filter((el) => el.id === afterTo)[0]
        .symbol.toUpperCase()}). At the time of writing, ${numberAm} ${dataAll
        .filter((el) => el.id === rest.join("-"))[0]
        .symbol.toUpperCase()} to ${dataAll.filter((el) => el.id === afterTo)[0].symbol.toUpperCase()} is equal to ${amountTo} ${dataAll
        .filter((el) => el.id === afterTo)[0]
        .symbol.toUpperCase()}.`;
    } else {
      document.title = "Currency Converter - Exchange Rates Today";

      document.getElementsByTagName("META")[2].content =
        "Free currency converter to convert most of the global currencies and crypto using live exchange rates today. ";
    }
  }, [convert_id, amountTo]);

  useEffect(() => {
    if (convert_id) {
      const [beforeTo, afterTo] = convert_id.split("-to-");
      const [numberAm, ...rest] = beforeTo.split("-");

      setUrlData({
        num: numberAm,
        from: rest.join("-").toUpperCase(),
        to: afterTo.toUpperCase(),
      });

      setCurrencyFrom(dataAll.filter((el) => el.id === rest.join("-"))[0]);
      setCurrencyTo(dataAll.filter((el) => el.id === afterTo)[0]);
      setAmountFrom(numberAm);

      setInputCurrencyFrom(dataAll.filter((el) => el.id === rest.join("-"))[0]);
      setInputCurrencyTo(dataAll.filter((el) => el.id === afterTo)[0]);
      setInputValue(numberAm);

      document.title = `Convert ${numberAm} ${dataAll.filter((el) => el.id === rest.join("-"))[0].symbol.toUpperCase()} to ${dataAll
        .filter((el) => el.id === afterTo)[0]
        .symbol.toUpperCase()} (${dataAll.filter((el) => el.id === rest.join("-"))[0].name} to ${
        dataAll.filter((el) => el.id === afterTo)[0].name
      }), ${dataAll.filter((el) => el.id === rest.join("-"))[0].name} Price in ${dataAll.filter((el) => el.id === afterTo)[0].name}`;

      dataAll.filter((el) => el.id === rest.join("-"))[0].isFiat
        ? dataAll.filter((el) => el.id === rest.join("-"))[0].name === "Gold"
          ? (document.querySelector("#exchange-image-from").src = GoldBar)
          : dataAll.filter((el) => el.id === rest.join("-"))[0].name === "Silver"
            ? (document.querySelector("#exchange-image-from").src = SilverBar)
            : dataAll.filter((el) => el.id === rest.join("-"))[0].name === "International Monetary Fund"
              ? (document.querySelector("#exchange-image-from").src = IMFIcon)
              : dataAll.filter((el) => el.id === rest.join("-"))[0].symbol
                ? (document.querySelector("#exchange-image-from").src = `https://flagcdn.com/40x30/${dataAll
                    .filter((el) => el.id === rest.join("-"))[0]
                    .code?.toLowerCase()}.png`)
                : (document.querySelector("#exchange-image-from").src = UnknownIcon)
        : dataAll.filter((el) => el.id === rest.join("-"))[0].image
          ? (document.querySelector("#exchange-image-from").src = dataAll.filter((el) => el.id === rest.join("-"))[0]?.image)
          : binanceCryptoIcons.has(dataAll.filter((el) => el.id === rest.join("-"))[0].symbol)
            ? (document.querySelector("#exchange-image-from").src = `https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${
                dataAll.filter((el) => el.id === rest.join("-"))[0].symbol
              }.svg`)
            : (document.querySelector("#exchange-image-from").src = UnknownIcon);

      dataAll.filter((el) => el.id === afterTo)[0].isFiat
        ? dataAll.filter((el) => el.id === afterTo)[0].name === "Gold"
          ? (document.querySelector("#exchange-image-to").src = GoldBar)
          : dataAll.filter((el) => el.id === afterTo)[0].name === "Silver"
            ? (document.querySelector("#exchange-image-to").src = SilverBar)
            : dataAll.filter((el) => el.id === afterTo)[0].name === "International Monetary Fund"
              ? (document.querySelector("#exchange-image-to").src = IMFIcon)
              : dataAll.filter((el) => el.id === afterTo)[0].symbol
                ? (document.querySelector("#exchange-image-to").src = `https://flagcdn.com/40x30/${dataAll
                    .filter((el) => el.id === afterTo)[0]
                    .code?.toLowerCase()}.png`)
                : (document.querySelector("#exchange-image-to").src = UnknownIcon)
        : dataAll.filter((el) => el.id === afterTo)[0].image
          ? (document.querySelector("#exchange-image-to").src = dataAll.filter((el) => el.id === afterTo)[0]?.image)
          : binanceCryptoIcons.has(dataAll.filter((el) => el.id === afterTo)[0].symbol)
            ? (document.querySelector("#exchange-image-to").src = `https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${
                dataAll.filter((el) => el.id === afterTo)[0].symbol
              }.svg`)
            : (document.querySelector("#exchange-image-to").src = UnknownIcon);
      // Values to Inputs
      // Start Convert
    }

    return () => {
      // setUrlData({ num: null, from: null, to: null });
      // setCurrencyFrom(null);
      // setCurrencyTo(null);
      // setAmountFrom(0);
      setAmountTo(0);
      setInputCurrencyFrom(null);
      setInputCurrencyTo(null);
      setInputValue(0);
      // setIs30DaysSelected(false);
    };
  }, [convert_id]);

  useEffect(() => {
    if (convert_id) {
      setTimeout(() => {
        if (!currencyFrom.isFiat && !currencyTo.isFiat) {
          axios
            .get(
              `https://api.coingecko.com/api/v3/simple/price?ids=${convert_id.split("-to-")[0].split("-").slice(1).join("-")},${
                convert_id.split("-to-")[1]
              }&vs_currencies=usd`
            )
            .then((result) => {
              console.log("Convert Crypto - Okay");
              let leftPrice = result.data[currencyFrom.id].usd;
              let rightPrice = result.data[currencyTo.id].usd;

              setAmountTo(((amountFrom * leftPrice) / rightPrice).toFixed(2));
              setDataPrice({ mode: 1, priceData: [leftPrice, rightPrice] });
            })
            .catch((error) => console.log(error));
        } else if (!currencyFrom.isFiat && currencyTo.isFiat) {
          axios
            .get(`https://api.coingecko.com/api/v3/simple/price?ids=${currencyFrom.id}&vs_currencies=${currencyTo.symbol}`)
            .then((result) => {
              console.log("Convert Crypto - Okay");
              let price = result.data[currencyFrom.id][currencyTo.symbol];
              setAmountTo(amountFrom * price);
              setDataPrice({ mode: 2, priceData: price });
            })
            .catch((error) => console.log(error));
        } else if (currencyFrom.isFiat && !currencyTo.isFiat) {
          axios
            .get(`https://api.coingecko.com/api/v3/simple/price?ids=${currencyTo.id}&vs_currencies=${currencyFrom.symbol}`)
            .then((result) => {
              console.log("Convert Crypto - Okay");
              let price = result.data[currencyTo.id][currencyFrom.symbol];
              setAmountTo((amountFrom / price).toFixed(2));
              setDataPrice({ mode: 3, priceData: price });
            })
            .catch((error) => console.log(error));
        } else {
          console.log(4);
        }
      }, 100);
    }
  }, [convert_id, currencyFrom, currencyTo, amountFrom]);

  const [priceChangeData, setPriceChangeData] = useState({
    "24h": 0,
    "7d": 0,
    "30d": 0,
  });

  useEffect(() => {
    if (currencyFrom.id !== "") {
      axios
        .get(`https://api.coingecko.com/api/v3/coins/${currencyFrom.id}/market_chart?vs_currency=usd&days=30&interval=daily`)
        .then((result) => {
          console.log("Price Change Data - Okay");
          setPriceChangeData({
            "24h": ((result.data.prices[30][1] - result.data.prices[29][1]) / result.data.prices[30][1]) * 100,
            "7d": ((result.data.prices[30][1] - result.data.prices[24][1]) / result.data.prices[24][1]) * 100,
            "30d": ((result.data.prices[30][1] - result.data.prices[0][1]) / result.data.prices[0][1]) * 100,
          });
        })
        .catch((error) => console.log(error));
    }
  }, [currencyFrom]);

  const location = useLocation(); // Отримуємо поточний шлях
  const breadcrumbs = useBreadcrumbs();

  // Функція для форматування тексту
  const formatBreadcrumb = (text) => {
    if (!isNaN(text)) {
      return `Page #${text}`;
    }
    return text.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    if (convert_id) {
      // Генерація розмітки Breadcrumbs
      const breadcrumbList = breadcrumbs.map(({ breadcrumb, match }, index) => ({
        "@type": "ListItem",
        position: index + 1,
        name: formatBreadcrumb(breadcrumb.props.children),
        item: `${window.location.origin}${match.pathname}`,
      }));

      const breadcrumbsJsonLd = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: breadcrumbList,
      };

      // Генерація розмітки FAQ
      const faqJsonLd = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [
          {
            "@type": "Question",
            name: `What is the current price of ${amountFrom} ${currencyFrom?.symbol.toUpperCase()} to ${currencyTo?.symbol.toUpperCase()}?`,
            acceptedAnswer: {
              "@type": "Answer",
              text: `The current price of ${amountFrom} ${currencyFrom?.symbol.toUpperCase()} is ${amountTo} ${currencyTo?.symbol.toUpperCase()}. It means you can exchange ${amountFrom} ${currencyFrom?.name} for ${amountTo} ${currencyTo?.symbol.toUpperCase()} at the current exchange rate.`,
            },
          },
          {
            "@type": "Question",
            name: `What do you mean by ${amountFrom} ${currencyFrom?.symbol.toUpperCase()} to ${currencyTo?.symbol.toUpperCase()} rate?`,
            acceptedAnswer: {
              "@type": "Answer",
              text: `The ${currencyFrom?.name} to ${currencyTo?.symbol.toUpperCase()} price means how much ${currencyTo?.name} you need to buy ${amountFrom} ${currencyFrom?.symbol.toUpperCase()}. This way, ${amountFrom} ${currencyFrom?.symbol.toUpperCase()} to ${currencyTo?.symbol.toUpperCase()} price means the amount of ${currencyTo?.name} you need to buy ${amountFrom} ${currencyFrom?.symbol.toUpperCase()}. On the chart given above, you can see the real-time ${currencyFrom?.symbol.toUpperCase()} to ${currencyTo?.symbol.toUpperCase()} price.`,
            },
          },
          {
            "@type": "Question",
            name: `How can I convert ${currencyFrom?.symbol.toUpperCase()} to ${currencyTo?.symbol.toUpperCase()}?`,
            acceptedAnswer: {
              "@type": "Answer",
              text: `You can convert ${currencyFrom?.name} to ${currencyTo?.name} by visiting cryptocurrency exchanges where you sell ${currencyFrom?.symbol.toUpperCase()} for ${currencyTo?.symbol.toUpperCase()}.`,
            },
          },
        ],
      };

      // Генерація розмітки Organization
      const organizationJsonLd = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Cryptocurrency Prices Now",
        alternateName: "CPN",
        url: "https://www.cryptocurrencypricesnow.com/",
        logo: "https://www.cryptocurrencypricesnow.com/logo.png", // Замість цього URL використайте URL вашого логотипу
        sameAs: [
          "https://x.com/crypto_cpn",
          "https://www.facebook.com/profile.php?id=61565411828199",
          "https://www.instagram.com/cryptocurrencypricesnow_off/",
          "https://www.tiktok.com/@cryptocurrencypricesnow?lang=en",
          "https://www.linkedin.com/company/cryptocurrency-prices-now/",
        ],
        organizationType: "News Media",
      };

      // Об'єднання Breadcrumbs, FAQ, і Organization в один JSON-LD об'єкт
      const jsonLdArray = [breadcrumbsJsonLd, faqJsonLd, organizationJsonLd];

      // Створення або оновлення тега <script> для JSON-LD
      let script = document.querySelector('script[type="application/ld+json"]');
      if (!script) {
        script = document.createElement("script");
        script.type = "application/ld+json";
        document.head.appendChild(script);
      }

      script.innerHTML = JSON.stringify(jsonLdArray);
    }

    // Очищення при демонтуванні компонента
    return () => {
      const script = document.querySelector('script[type="application/ld+json"]');
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [breadcrumbs, convert_id]);

  return (
    <div className="wrapper conv-content">
      {convert_id ? (
        <>
          <Helmet>
            <link rel="canonical" href={window.location.href} />
          </Helmet>

          <Breadcrumbs />
          <div className="conv-basic content-left">
            <h1>
              Convert {amountFrom} {currencyFrom?.symbol.toUpperCase()} to {currencyTo?.symbol.toUpperCase()} ( {amountFrom} {currencyFrom.name} to{" "}
              {currencyTo?.name} )
            </h1>
            <div>
              How much is {amountFrom} {currencyFrom?.symbol.toUpperCase()} worth in {currencyTo?.symbol.toUpperCase()}
            </div>
            <h2>
              Current value of {amountFrom} {currencyFrom?.symbol.toUpperCase()} in {currencyTo?.symbol.toUpperCase()} is {amountTo}{" "}
              {currencyTo.symbol.toUpperCase()}
            </h2>
            <p>
              You are viewing real time data for the current value of {currencyFrom?.symbol.toUpperCase()} in {currencyTo?.name} ({" "}
              {currencyTo?.symbol.toUpperCase()}). At the time of writing, {amountFrom} {currencyFrom?.symbol.toUpperCase()} to{" "}
              {currencyTo?.symbol.toUpperCase()} is equal to
              {amountTo} {currencyTo?.symbol.toUpperCase()}. Because cryptocurrency prices fluctuate frequently, we recommend revisiting this page
              often to get the most up-to-date conversion rate for {amountFrom} {currencyFrom?.symbol.toUpperCase()} to{" "}
              {currencyTo?.symbol.toUpperCase()}
            </p>
            {/* <p>
              This is the real-time data fetched from our partnered price aggregators. At the moment, you are looking at the conversion of{" "}
              {amountFrom} {currencyFrom?.symbol.toUpperCase()} when {amountFrom} {currencyFrom?.symbol.toUpperCase()} is valued at {amountTo}{" "}
              {currencyTo?.symbol.toUpperCase()}. Since prices change often, it is recommended you come back to this page again to check the updated
              conversion value again.
            </p> */}
          </div>
        </>
      ) : (
        <div className="conv-basic content-center">
          <h1>Global Crypto Converter</h1>
          <h2>Convert your fiat coins to crypto and vice versa</h2>
        </div>
      )}

      <div className="conv-converter">
        <div className="converter-top">
          <div className="conv-left">
            <img id="exchange-image-from" src={UnknownIcon} alt="" />
            <Autocomplete
              value={inputCurrencyFrom}
              disablePortal
              clearOnBlur={false}
              clearOnEscape={false}
              id="currency-from"
              options={data250}
              sx={{ width: 250 }}
              onChange={(event, value) => {
                setInputCurrencyFrom(value);

                value.isFiat
                  ? value.name === "Gold"
                    ? (document.querySelector("#exchange-image-from").src = GoldBar)
                    : value.name === "Silver"
                      ? (document.querySelector("#exchange-image-from").src = SilverBar)
                      : value.name === "International Monetary Fund"
                        ? (document.querySelector("#exchange-image-from").src = IMFIcon)
                        : value.symbol
                          ? (document.querySelector("#exchange-image-from").src = `https://flagcdn.com/40x30/${value.code?.toLowerCase()}.png`)
                          : (document.querySelector("#exchange-image-from").src = UnknownIcon)
                  : value.image
                    ? (document.querySelector("#exchange-image-from").src = value?.image)
                    : binanceCryptoIcons.has(value.symbol)
                      ? (document.querySelector("#exchange-image-from").src =
                          `https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${value.symbol}.svg`)
                      : (document.querySelector("#exchange-image-from").src = UnknownIcon);
              }}
              filterOptions={(options, state) => {
                if (state.inputValue.length > 2) {
                  return dataAll.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()));
                }
                return data250;
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getoptionkey={uuidv4()}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.isFiat ? (
                    option.name === "Gold" ? (
                      <img loading="lazy" width="20" src={GoldBar} alt="" />
                    ) : option.name === "Silver" ? (
                      <img loading="lazy" width="20" src={SilverBar} alt="" />
                    ) : option.name === "International Monetary Fund" ? (
                      <img loading="lazy" width="20" src={IMFIcon} alt="" />
                    ) : option.symbol ? (
                      <img loading="lazy" width="20" src={`https://flagcdn.com/40x30/${option.code?.toLowerCase()}.png`} alt="" />
                    ) : (
                      <img loading="lazy" width="20" src={UnknownIcon} alt="" />
                    )
                  ) : option.image ? (
                    <img loading="lazy" width="20" src={option.image} alt="" />
                  ) : binanceCryptoIcons.has(option.symbol) ? (
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${option.symbol}.svg`}
                      alt=""
                    />
                  ) : (
                    <img loading="lazy" width="20" src={UnknownIcon} alt="" />
                  )}
                  <p className="currencies-row">
                    {option.name}
                    <span> {!option.isFiat && option.symbol.toUpperCase()}</span>
                  </p>
                </Box>
              )}
              renderInput={(params) => <TextField {...params} label="Coin from" />}
            />
            <TextField
              value={inputValue}
              id="amount-from"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              sx={{ width: 250 }}
              onChange={(event) => setInputValue(event.target.value)}
              label="Enter amount"
            />
          </div>
          <div
            className="conv-center"
            aria-label="exchange"
            // onClick={() => {
            //   if (!currencyFrom.isFiat && !currencyTo.isFiat) {
            //     axios
            //       .get(`https://api.coingecko.com/api/v3/simple/price?ids=${currencyFrom.id},${currencyTo.id}&vs_currencies=usd`)
            //       .then((result) => {
            //         let leftPrice = result.data[currencyFrom.id].usd;
            //         let rightPrice = result.data[currencyTo.id].usd;
            //         setAmountTo(((amountFrom * leftPrice) / rightPrice).toFixed(2));
            //       })
            //       .catch((error) => console.log(error));
            //   } else if (!currencyFrom.isFiat && currencyTo.isFiat) {
            //     axios
            //       .get(`https://api.coingecko.com/api/v3/simple/price?ids=${currencyFrom.id}&vs_currencies=${currencyTo.symbol}`)
            //       .then((result) => {
            //         let price = result.data[currencyFrom.id][currencyTo.symbol];
            //         setAmountTo(amountFrom * price);
            //       })
            //       .catch((error) => console.log(error));
            //   } else if (currencyFrom.isFiat && !currencyTo.isFiat) {
            //     axios
            //       .get(`https://api.coingecko.com/api/v3/simple/price?ids=${currencyTo.id}&vs_currencies=${currencyFrom.symbol}`)
            //       .then((result) => {
            //         let price = result.data[currencyTo.id][currencyFrom.symbol];
            //         setAmountTo((amountFrom / price).toFixed(2));
            //       })
            //       .catch((error) => console.log(error));
            //   }
            // }}
          >
            <MdKeyboardArrowLeft />
            <MdKeyboardArrowRight />
          </div>
          <div className="conv-right">
            <img id="exchange-image-to" src={UnknownIcon} alt="" />
            <Autocomplete
              value={inputCurrencyTo}
              disablePortal
              id="currency-to"
              options={data250}
              sx={{ width: 250 }}
              onChange={(event, value) => {
                setInputCurrencyTo(value);

                value.isFiat
                  ? value.name === "Gold"
                    ? (document.querySelector("#exchange-image-to").src = GoldBar)
                    : value.name === "Silver"
                      ? (document.querySelector("#exchange-image-to").src = SilverBar)
                      : value.name === "International Monetary Fund"
                        ? (document.querySelector("#exchange-image-to").src = IMFIcon)
                        : value.symbol
                          ? (document.querySelector("#exchange-image-to").src = `https://flagcdn.com/40x30/${value.code?.toLowerCase()}.png`)
                          : (document.querySelector("#exchange-image-to").src = UnknownIcon)
                  : value.image
                    ? (document.querySelector("#exchange-image-to").src = value?.image)
                    : binanceCryptoIcons.has(value.symbol)
                      ? (document.querySelector("#exchange-image-to").src =
                          `https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${value.symbol}.svg`)
                      : (document.querySelector("#exchange-image-to").src = UnknownIcon);
              }}
              filterOptions={(options, state) => {
                if (state.inputValue.length > 2) {
                  return dataAll.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()));
                }
                return data250;
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getoptionkey={uuidv4()}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.isFiat ? (
                    option.name === "Gold" ? (
                      <img loading="lazy" width="20" src={GoldBar} alt="" />
                    ) : option.name === "Silver" ? (
                      <img loading="lazy" width="20" src={SilverBar} alt="" />
                    ) : option.name === "International Monetary Fund" ? (
                      <img loading="lazy" width="20" src={IMFIcon} alt="" />
                    ) : option.symbol ? (
                      <img loading="lazy" width="20" src={`https://flagcdn.com/40x30/${option.code?.toLowerCase()}.png`} alt="" />
                    ) : (
                      <img loading="lazy" width="20" src={UnknownIcon} alt="" />
                    )
                  ) : option.image ? (
                    <img loading="lazy" width="20" src={option.image} alt="" />
                  ) : binanceCryptoIcons.has(option.symbol) ? (
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${option.symbol}.svg`}
                      alt=""
                    />
                  ) : (
                    <img loading="lazy" width="20" src={UnknownIcon} alt="" />
                  )}
                  <p className="currencies-row">
                    {option.name}
                    <span> {!option.isFiat && option.symbol.toUpperCase()}</span>
                  </p>
                </Box>
              )}
              renderInput={(params) => <TextField {...params} label="Coin to" />}
            />
            <TextField InputProps={{ readOnly: true }} value={amountTo} id="amount-to" sx={{ width: 250 }} />
          </div>
        </div>
        <div className="converter-bottom">
          <div className="conv-info">
            {amountTo !== 0 && (
              <p>
                {amountFrom} {currencyFrom.name} = {amountTo} {currencyTo.name}
              </p>
            )}
          </div>
          {!!inputCurrencyFrom && !!inputCurrencyTo && inputValue > 0 ? (
            <Link className="conv-button" to={`/convert/${inputValue}-${inputCurrencyFrom?.id}-to-${inputCurrencyTo?.id}`}>
              View Conversion <FiArrowUpRight className="conv-icon" />
            </Link>
          ) : (
            <button className="conv-button" onClick={() => alert("Please select currency and enter amount")}>
              View Conversion <FiArrowUpRight className="conv-icon" />
            </button>
          )}
        </div>
      </div>

      {convert_id && (
        <div className="conv-stats">
          <div className="stats-info">
            <div className="stats-price">
              <h2 className="h2-mb">
                {currencyFrom?.symbol.toUpperCase()} to USD Price stats for {months[new Date().getMonth()]} {new Date().getFullYear()}
              </h2>
              <div className="st-price-blocks">
                <div className="st-price-block">
                  <p className="price-block-text">24hr change</p>
                  <p className={priceChangeData["24h"]?.toFixed(2) > 0 ? "price-block-perc-green" : "price-block-perc-red"}>
                    {priceChangeData["24h"]?.toFixed(2)}%
                  </p>
                </div>
                <div className="st-price-block">
                  <p className="price-block-text">7d change</p>
                  <p className={priceChangeData["7d"]?.toFixed(2) > 0 ? "price-block-perc-green" : "price-block-perc-red"}>
                    {priceChangeData["7d"]?.toFixed(2)}%
                  </p>
                </div>
                <div className="st-price-block">
                  <p className="price-block-text">30d change</p>
                  <p className={priceChangeData["30d"]?.toFixed(2) > 0 ? "price-block-perc-green" : "price-block-perc-red"}>
                    {priceChangeData["30d"]?.toFixed(2)}%
                  </p>
                </div>
              </div>
              <p>
                With a price decline of {priceChangeData["24h"]?.toFixed(2)}% in the last 24 hours, {currencyFrom?.name} (
                {currencyFrom?.symbol.toUpperCase()}) is currently trading at {currencyTo?.symbol.toUpperCase()} {amountTo}. At this price, you can
                buy {amountFrom} {currencyFrom?.symbol.toUpperCase()} to {currencyTo?.symbol.toUpperCase()} by paying {amountTo} {currencyTo?.name}.
                The chart given above shows the fluctuating price of {amountFrom} {currencyFrom?.symbol.toUpperCase()} in the last 30 days.
              </p>
              {/* <p>
                Over the past 30 days, {currencyFrom?.symbol.toUpperCase()} has gained {priceChangeData["30d"]?.toFixed(2)}% to USD. The trend and
                price charts for the past week shows that {currencyFrom?.symbol.toUpperCase()} has gained {priceChangeData["7d"]?.toFixed(2)}% to USD
                and over the past 24 hours, {currencyFrom?.symbol.toUpperCase()} has gained {priceChangeData["24h"]?.toFixed(2)}% to USD.
              </p> */}
            </div>
            <div className="stats-chart">
              <div className="stats-chart-title">
                <h2>
                  {urlData.num} {currencyFrom?.symbol.toUpperCase()} to USD Price Chart
                </h2>

                <div className="chart-buttons">
                  {is30DaysSelected === 7 ? (
                    <>
                      <div className="chart-button-active" onClick={() => setIs30DaysSelected(7)}>
                        7D
                      </div>
                      <div className="chart-button" onClick={() => setIs30DaysSelected(30)}>
                        30D
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="chart-button" onClick={() => setIs30DaysSelected(7)}>
                        7D
                      </div>
                      <div className="chart-button-active" onClick={() => setIs30DaysSelected(30)}>
                        30D
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="chart-data">
                {/* {is30DaysSelected === 7 ? "7 Days" : "30 Days"} */}
                <PriceChart data={coinChart} days={is30DaysSelected} />

                {/* <div className="chart-volume">{<VolumeChart data={coinChart} days={is30DaysSelected} />}</div> */}
              </div>
            </div>
            <div className="stats-tables">
              <div className="stats-table">
                <h3>
                  {currencyFrom?.symbol.toUpperCase()} to {currencyTo?.symbol.toUpperCase()}
                </h3>
                <table>
                  <thead>
                    <tr>
                      <td>{currencyFrom?.symbol.toUpperCase()}</td>
                      <td>{currencyTo?.symbol.toUpperCase()}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/1-${currencyFrom?.symbol.toUpperCase()}-to-${currencyTo?.id}`}>
                          1 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/1-${currencyFrom?.symbol.toUpperCase()}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 1)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/5-${currencyFrom?.symbol.toUpperCase()}-to-${currencyTo?.id}`}>
                          5 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/5-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 5)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/10-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          10 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/10-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 10)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/25-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          25 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/25-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 25)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/50-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          50 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/50-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 50)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/100-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          100 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/100-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 100)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/500-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          500 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/500-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 500)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/1000-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          1000 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/1000-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 1000)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/5000-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          5000 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/5000-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 5000)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/10000-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          10000 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/10000-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 10000)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/50000-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          50000 {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/50000-${currencyFrom?.id}-to-${currencyTo?.id}`}>
                          {tableConverter(dataPrice.mode, dataPrice.priceData, 50000)} {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="stats-table">
                <h3>
                  {currencyTo?.symbol.toUpperCase()} to {currencyFrom?.symbol.toUpperCase()}
                </h3>
                <table>
                  <thead>
                    <tr>
                      <td>{currencyTo?.symbol.toUpperCase()}</td>
                      <td>{currencyFrom?.symbol.toUpperCase()}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/1-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          1 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/1-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 1)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/5-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          5 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/5-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 5)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/10-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          10 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/10-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 10)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/25-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          25 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/25-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 25)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/50-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          50 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/50-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 50)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/100-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          100 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/100-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 100)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/500-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          500 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/500-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 500)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/1000-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          1000 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/1000-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 1000)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/5000-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          5000 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/5000-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 5000)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/10000-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          10000 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/10000-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 10000)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a rel="noreferrer" href={`/convert/50000-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          50000 {currencyTo?.symbol.toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a rel="noreferrer" href={`/convert/50000-${currencyTo?.id}-to-${currencyFrom?.id}`}>
                          {tableConverter(dataPrice.mode + "R", dataPrice.priceData, 50000)} {currencyFrom?.symbol.toUpperCase()}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="stats-faq">
              <h2>Frequently Asked Questions (FAQs)</h2>

              <div>
                <h3>
                  What is the current price of {amountFrom} {currencyFrom?.symbol.toUpperCase()} to {currencyTo?.symbol.toUpperCase()}?
                </h3>
                <p>
                  The current price of {amountFrom} {currencyFrom?.symbol.toUpperCase()} is {amountTo} {currencyTo?.symbol.toUpperCase()}. It means
                  you can exchange {amountFrom} {currencyFrom?.name} for {amountTo} {currencyTo?.symbol.toUpperCase()} at the current exchange rate.
                </p>
              </div>

              <div>
                <h3>
                  What do you mean by {amountFrom} {currencyFrom?.symbol.toUpperCase()} to {currencyTo?.symbol.toUpperCase()} rate?{" "}
                </h3>
                <p>
                  The {currencyFrom?.name} to {currencyTo?.symbol.toUpperCase()} price means how much {currencyTo?.name} you need to buy {amountFrom}{" "}
                  {currencyFrom?.symbol.toUpperCase()}. This way, {amountFrom} {currencyFrom?.symbol.toUpperCase()} to{" "}
                  {currencyTo?.symbol.toUpperCase()} price means the amount of {currencyTo?.name} you need to buy {amountFrom}{" "}
                  {currencyFrom?.symbol.toUpperCase()}. On the chart given above, you can see the real-time real-time{" "}
                  {currencyFrom?.symbol.toUpperCase()} to {currencyTo?.symbol.toUpperCase()} price.{" "}
                </p>
              </div>

              <div>
                <h3>
                  How can I convert {currencyFrom?.symbol.toUpperCase()} to {currencyTo?.symbol.toUpperCase()}?
                </h3>
                <p>
                  You can convert {currencyFrom?.name} to {currencyTo?.name} by visiting cryptocurrency exchanges where you sell{" "}
                  {currencyFrom?.symbol.toUpperCase()} for {currencyTo?.symbol.toUpperCase()}.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Converter;
